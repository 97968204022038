/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Cookies from 'js-cookie';
import focusLock from 'dom-focus-lock';
import ResponsiveAutoHeight from 'responsive-auto-height';

// IE 11 polyfill for Swiper
import 'core-js/modules/es.number.is-nan';

// Calculate 
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	var hero = document.querySelector('.hero');
	if (hero) {
		document.documentElement.style.setProperty('--hh', `${hero.offsetHeight}px`);
	}
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
})

// Equellize height
if (document.querySelectorAll('.match-height').length) {
	new ResponsiveAutoHeight('.match-height');
}

import Headroom from "headroom.js";
var myElement = document.querySelector(".header");
var headroom = new Headroom(myElement);
headroom.init();

// Swiper
var sliders = document.querySelectorAll('.content-block.is-slider-block, .slider.is-media-slider-items');
if (sliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		sliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				watchOverflow: true,
				keyboard: {
					enabled: true,
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (el.querySelectorAll('.swiper-slide')[index].getAttribute('data-bullet-name')) + '</span>';
					}
				}
			});
		});
	})();
}

// Testimonial swiper
var testimonialSliders = document.querySelectorAll('.content-block.is-testimonial-block');
if (testimonialSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		testimonialSliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				watchOverflow: true,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				}
			});
		});
	})();
}

// Product swiper
var productSliders = document.querySelectorAll('.content-block.is-products-block');
if (productSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		productSliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				breakpoints: {
					// when window width is >= 320px
					320: {
						slidesPerView: 1,
						spaceBetween: 24
					},
					// when window width is >= 1024px
					1024: {
						slidesPerView: 2,
						spaceBetween: 48
					}
				},
				watchOverflow: true,
				spaceBetween: 48,
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				}
			});
		});
	})();
}

// Categories swiper
var categoriesSliders = document.querySelectorAll('.content-block.is-categories-block');
if (categoriesSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		categoriesSliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				effect: "fade",
				watchOverflow: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: true
				},
				speed: 1500,
				slidesPerView: 1,
				keyboard: {
					enabled: true
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (el.querySelectorAll('.swiper-slide')[index].getAttribute('data-bullet-name')) + '</span>';
					}
				}
			});
		});
	})();
}

//News swiper
var newsSwiper = document.querySelectorAll('.content-block.is-news-slider-block');
if (newsSwiper.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		newsSwiper.forEach(el => {
			const columnBlock = el.querySelector('.columns.shortcuts'),
				swiperItem = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				swiperButtons = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtons.setAttribute('class', 'swiper-buttons');
			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			swiperButtons.appendChild(swiperButtonNext);
			swiperButtons.appendChild(swiperButtonPrev);
			swiperContainer.parentElement.appendChild(swiperButtons);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			var slidesPerView = 1,
				spaceBetween = 48,
				breakpoints = { 640: { slidesPerView: 2 } };

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: slidesPerView,
				breakpoints: breakpoints,
				spaceBetween: spaceBetween,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

// Logo block slider
var logoBlockSliders = document.querySelectorAll('.logo-block');
if (logoBlockSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		logoBlockSliders.forEach(el => {

			const columnBlock = el.querySelector('.columns'),
				swiperItems = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);
			el.classList.add('has-swiper');

			swiperItems.forEach(si => {
				si.classList.remove('column');
				si.classList.add('swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 2,
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				keyboard: {
					enabled: true
				},
				spaceBetween: 48,
				breakpoints: {
					768: {
						slidesPerView: 3,
					},
					1024: {
						slidesPerView: 4,
					},
					1280: {
						slidesPerView: 5,
					},
					1440: {
						slidesPerView: 6,
					}
				}
			});
		});
	})();
}

//Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav-toggler'),
	navigation = document.querySelector('.nav'),
	navigationContainer = document.querySelector('.nav .nav-wrapper');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: false,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1255x'
	});
}

loadLightGallery();

// Jump to submitted form
document.querySelectorAll('.content-block.is-form-block').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (form) {
		if (!anchor) {
			anchor = el.querySelector('.form').getAttribute('id');
		}
		else {
			anchor = anchor.getAttribute('id');
		}

		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});

// Toasts
if (window.toasts) {
	const toastsCookieName = 'toasts',
		toastsCookieOptions = {
			sameSite: 'strict',
			secure: true
		};

	if (window.toasts.length) {
		(async () => {
			const Toasts = (await import('./modules/toasts.js')).default;
			new Toasts(window.toasts, toastsCookieName, toastsCookieOptions);
		})();
	} else {
		// Delete cookie if there are no active toasts
		Cookies.remove(toastsCookieName, toastsCookieOptions);
	}
}

// Lazy load video
var lazyVideos = document.querySelectorAll('.video.lazy');
if (lazyVideos.length) {
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
}